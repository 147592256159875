$(document).ready(function(){
	$(window).load(function(){
    $('#home-blog-link').on('click', '.home-blog-row', function() {
      var $url  = $(this).find('.custom-link .wpb_wrapper').text()
      if ($url) { // require a URL
          window.location = $url; // redirect
      }
      return false;
    });
    $('#home-services').on('click', '.home-serv-link', function() {
      var $url  = $(this).find('.custom-link .wpb_wrapper').text()
      if ($url) { // require a URL
          window.location = $url; // redirect
      }
      return false;
    });
    //MATCH HEIGHT
    $('.equal-height').matchHeight();

		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		// INITIATIONS
		AOS.init();

		// CUSTOM FUNCTIONS
    close_popup();
    sign_in_cancel();
    active_state();
    open_videos();
    checkEmpty();
    show_review_form();
    custom_file_upload();
    show_modal();
    objLightcase();
		custom_slick_sliders();
    zoom_image();
		carouselAnimation();
		tableClass();
		mobileLayout();
		wrapperHolder( $pageHeight, $navHeight, $footerHeight );
		cf7formsubmit();
		caretMenu();

		$('.loader-overlay').fadeOut(200);

	});

	$(window).on('resize', function(){
		// VARIABLES
		var $pageHeight = $(window).height(),
		$pageWidth = $(window).width(),
		$navHeight = $('header.main-header').outerHeight(),
		$footerHeight = $('footer.footer').outerHeight(),
		$mainWrapper = $('.wrapper-holder');

		wrapperHolder( $pageHeight, $navHeight, $footerHeight );

	});
});

/* MODERNIZR LAYOUT - This serves as the the media query inside the Javascript */
if( Modernizr.mq('(min-width: 1200px)') ) {
	equalizer();
}
else if( Modernizr.mq('(min-width: 992px)') && Modernizr.mq('(max-width: 1199px)') ) {
	equalizer();
}
else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
	equalizer();
}
else{
}

// FUNCTION LISTS
/*
* Method smooth scrolls to given anchor point
*/

function pre( $object ){
	console.log( $object );
}

function smoothScrollTo(anchor) {
	var duration = 400; //time (milliseconds) it takes to reach anchor point
	var targetY = $(anchor).offset().top;
	$("html, body").animate({
		"scrollTop" : targetY
	}, duration, 'easeInOutCubic');
}

function carouselAnimation(){
	/* Demo Scripts for Bootstrap Carousel and Animate.css article
	* on SitePoint by Maria Antonietta Perna
	*/
  //Function to animate slider captions 
  function doAnimations( elems ) {
    //Cache the animationend event in a variable
    var animEndEv = 'webkitAnimationEnd animationend';
    
    elems.each(function () {
      var $this = $(this),
        $animationType = $this.data('animation');
      $this.addClass($animationType).one(animEndEv, function () {
        $this.removeClass($animationType);
      });
    });
  }
  
  //Variables on page load 
  var $myCarousel = $('.carousel'),
    $firstAnimatingElems = $myCarousel.find('.item:first').find("[data-animation ^= 'animated']");
      
  //Initialize carousel 
  $myCarousel.carousel();
  
  //Animate captions in first slide on page load 
  doAnimations($firstAnimatingElems);
  
  //Pause carousel  
  $myCarousel.carousel('pause');
  
  
  //Other slides to be animated on carousel slide event 
  $myCarousel.on('slide.bs.carousel', function (e) {
    var $animatingElems = $(e.relatedTarget).find("[data-animation ^= 'animated']");
    doAnimations($animatingElems);
  });

  $('.carousel').carousel();
}

function tableClass(){
	var $tables = $(document).find('table');
	if( $tables ) {
		$tables.wrap('<div class="table-responsive"></div>');
		$tables.addClass('table');
	}
}
function wrapperHolder( $pageHeight, $navHeight, $footerHeight ){
	var $pgHeight, $nvHeight, $ftHeight;
	
	if( $pageHeight ) {
		$pgHeight = $pageHeight;
	} else {
		$pgHeight = 0;
	}

	if( $navHeight ) {
		$nvHeight = $navHeight;
	} else {
		$nvHeight = 0;
	}

	if( $footerHeight ) {
		$ftHeight = $footerHeight;
	} else {
		$ftHeight = 0;
	}

	$('.wrapper-holder').css({
		'min-height': $pgHeight - $nvHeight,
		'margin-top': $nvHeight,
		'padding-bottom': $ftHeight
	});
	$('.main-layout').css({
		'min-height': $pgHeight - ( $nvHeight + $ftHeight ),
	});
}
function mobileLayout(){
	// MOBILE MENU LAYOUT
	$('.sidepanel .menu > .menu-item-has-children').addClass('dropdown row-size');
	$('.sidepanel .menu > .menu-item-has-children > a').each(function(){
		var $curr = $(this);
		$curr.addClass('column-top nav-title');
		$('<span class="fa fa-plus dropdown-toggle nav-control column-top" data-toggle="dropdown" style="min-height: '+ $curr.outerHeight() +'px;"></span>').insertAfter( $curr );			
	});
	$('.sidepanel .menu > .menu-item-has-children > .sub-menu').addClass('dropdown-menu');
	// MOBILE MENU
	if(!$('.sidepanel').hasClass('sidepanel-out')){
		$('.close-sidemenu').hide();
	}
	$('.mobile-menu-btn').click(function(){
    $(this).parent('.mobile-menu').addClass('d-none');
    $('.sidepanel').toggleClass("sidepanel-out" );
    $(this).toggleClass('toggle-mobile-menu');
    if(!$('.sidepanel').hasClass('sidepanel-out')){
      $('.close-sidemenu').hide();
    } else {
			$('.close-sidemenu').show();
		}
	});
	$('.close-sidemenu').click(function(){
		$('.sidepanel').toggleClass("sidepanel-out");
		$(this).hide();
	});
	$('.sidepanel li a').click(function(){
		$(this).find('.fa-plus').toggleClass('fa-minus');
	});

  $('.close-menu-btn').click(function(){
     $('.mobile-menu').removeClass('d-none');
  });

	// BACK TO TOP
	$('.back-to-top').hide(); // HIDE ON FIRST LOAD
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn();
		} else {
			$('.back-to-top').fadeOut();
		}
	});
	$('.back-to-top a').click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	$(document).on('click', '.mobile-menu-btn', function(){
		$('.menu-button').toggleClass('active');
	});
}

function cf7formsubmit(){
	$(document).on('click', '.wpcf7-submit', function(){
		var $btn = $(this);
	  var $ajaxLoader = $('.ajax-loader');
	  
	  $btn.addClass('loading');
	  $ajaxLoader.addClass('visible');

		var sitelink = $('.usd').data('usdacct');
		document.addEventListener( 'wpcf7mailsent', function( event ) {
		  location = sitelink + '/thank-you/';
		}, false );
		document.addEventListener( 'wpcf7invalid', function( event ) {
			$btn.removeClass('loading');
		 	$ajaxLoader.removeClass('visible');
		}, false );
	});
}


function locations($country, $state, $city){
	// Sample Field
	// "select.csCountryOption", "select.csStateOption", "select.csCityOption"
	filter_country_state_city($country, $state, $city);
}

function objLightcase(){
	$('a[data-rel^=lightcase]').lightcase({
    forceHeight: true,
    forceWidth: true,
    inline: {
      height: '1000',
      width: '1000'
    },
    maxWidth: '1000',
    maxHeight: '900',
    video: {
      autoplay: true,
      width: 800,
      height: 600
    }
  });

}

function responsiveSlick(){
	$('.slick-responsive').each(function(){
		var breaks = $(this).data('breakpoints').split('|'),
		breakArr = [],
		arrowsval;
		$.each(breaks, function(i, items){
			breakArr.push({
				breakpoint: parseInt(items.split(',')[0]),
	      settings: {
	        slidesToShow: parseInt(items.split(',')[1])
	      }
			});
		});
		if( $(this).data('arrows') ) {
			arrowsval = true;
		} else {
			arrowsval = false;
		}
		$(this).slick({
		  dots: false,
		  infinite: true,
		  speed: 300,
		  autoplay: true,
		  arrows: arrowsval,
		  slidesToShow: $(this).data('defaultview'),
		  slidesToScroll: 1,
		  adaptiveHeight: true,
		  asNavFor: $(this).data('thumb'),
		  responsive : breakArr,
		  prevArrow : '<button type="button" class="slick-prev slick-controller fa fa-angle-left"></button>',
		  nextArrow : '<button type="button" class="slick-next slick-controller fa fa-angle-right"></button>',
		});
	});
}

function equalizer(){
	var maxHeight = 0;
	$(".equalize").each(function(){
	   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".equalize").height(maxHeight);

	var productHeight = 0;
	$(".hp-equalize").each(function(){
	   if ($(this).height() > productHeight) { productHeight = $(this).height(); }
	});
	$(".hp-equalize").height(productHeight);
}

function caretMenu(){
	$('.desktop-menu .nav .menu li').each( function(){
		var menu = $(this);
		if( menu.find('.sub-menu').length > 0 ) {
			menu.find('a').first().append('<span class="fas fa-angle-down"></span>');
		}
	});
}


function custom_slick_sliders() {

  $('.ig-feed-slider').slick({
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 6,
      arrows: true,
      dots: false,
      autoplay: false,
      responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
          },
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
          },
          {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
      ],
  });

  $('.product-slider').slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      dots: false,
      autoplay: false,
      responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
          },
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
          },
          {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
      ],
  });

  $('.related-products-slider').slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      dots: false,
      autoplay: false,
      responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
          },
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
          },
          {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
      ],
  });
  $('.rp-slider').slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      dots: false,
      autoplay: false,
      responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
          },
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
          },
          {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
      ],
  });
  $('#main-img-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    cssEase: 'linear'
  });
  $('#thumbnail-img-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    asNavFor: '#main-img-slider',
    dots: false,
    arrows: false,
    focusOnSelect: true
  });
  $('#main-img-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
     var img = $(slick.$slides[nextSlide]).find("img");
     $('.zoomWindowContainer,.zoomContainer').remove();
     $(img).elevateZoom({
    });
  });
}

function show_modal() {
  $('.service-wrap').on('click', '.btn-signup', function(e) {
    e.preventDefault();
    // var $categories = $('#service-wrap').find('.service'),
    //     $btn_title  = $(this).data('btntitle');
    // $('#selectCategory').empty();
    // $($categories).each( function(key, value) {
    //   var $title = $(value).data('title');
    //   if( $title === $btn_title) {
    //     $('#selectCategory').append('<option value="'+$title+'" selected>'+$title+'</option');
    //   } else {
    //     $('#selectCategory').append('<option value="'+$title+'">'+$title+'</option');
    //   }
    // });
    
    var $service_id   = $(this).data('serviceid');

    $('#'+$service_id).modal('show');

    // if( $service_id == 766 ) {
    //   $('#select-franchise').val( $title );
    //   $('#franchise-modal').modal('show');
    // }
    // else if( $service_id == 765 ) {
    //   $('#select-dealership').val( $title );
    //   $('#dealership-modal').modal('show');
    // }
    // else if( $service_id == 764 ) {
    //   $('#select-distributorship').val( $title );
    //   $('#distributorship-modal').modal('show');
    // }
    // else if( $service_id == 758 ) {
    //   $('#select-tolling').val( $title );
    //   $('#tolling-modal').modal('show');
    // }
  });

  $('#header-search').on('click', function(e) {
    e.preventDefault();
    $('.search-modal').modal('show');
  });

  var $delay = $('#pop-up-delay').val();
  setTimeout(function(){
    $('.pop-up-modal').modal('show');
  }, $delay);
}

function custom_file_upload() {
  $('#sub-file').on('click', function() {
    $('#txt-cv').trigger('click');
  });
  $('#txt-cv').on('change', function() {
    var $file_url =  $(this).val().replace(/C:\\fakepath\\/i, '');
    $('#sub-file').val( $file_url );
    if ( $('#sub-file').val() ) {
        $('#sub-file').removeClass('empty');
    }
  });
  $('.wpcf7-submit').on('click', function (e) {
  });
  document.addEventListener( 'wpcf7invalid', function( event ) {
    var $file_field_cache   =   $('#txt-cv');
    if ( !$('#sub-file').val() ) {
      $('#sub-file').addClass('empty');
    }
  }, false );
}

function show_review_form() {
  $('#show-review').on('click', function(e) {
    e.preventDefault();
    var $status = $(this).attr('data-status');

    if( $status == 'inactive' ) {
      $('#respond').find('.comment-form-comment').fadeIn();
      $('#respond').find('.form-submit').fadeIn();
      $(this).attr('data-status', 'active');
      $(this).text('Close review');
    }
    else {
      $('#respond').find('.comment-form-comment').fadeOut();
      $('#respond').find('.form-submit').fadeOut();
      $(this).attr('data-status', 'inactive');
      $(this).text('Write a review');
    }
  });
}

function zoom_image() {
   $('.main-img-slider .slick-current img').elevateZoom({
      lensSize    : 200
   });
}

function checkEmpty(){
  $('#head-search').on('click', function() {
    var isEmpty = $('#head-input').val();
    if (!isEmpty) {
      $('#head-input').addClass('empty-field');
        return false;
    }else{
        $('#head-input').removeClass('empty-field');
        return true;
    }
  });
}

function open_videos() {
  $('#about-us-big-video').on('click', function() {
    $('#big-vid-link').click();
  });
  $('#home-big-video').on('click', function() {
    $('#big-vid-link').click();
  });
}

function active_state() {
  var $current_page = $('#current-page').val();
  if($current_page && $current_page == 'single-blog') {
    $('.blog-menu').addClass('current-menu-item');
  }
}

function sign_in_cancel() {
  $('#btn-cancel').on('click', function(e) {
    e.preventDefault();
    $('.sign-in-modal').modal('toggle');
  });
}

function close_popup() {
  $('#btn-popup').on('click', function(e) {
    e.preventDefault();
    $('.pop-up-modal').modal('toggle');
  });
}